// Here you can add other styles

.adxba {

    &-card {
        margin-bottom: 1.5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fafbfe;
        background-clip: border-box;
        border: none;
        border-radius: 0.55rem;


        &-header{
            padding: 1rem 1rem;
            margin-bottom: 0;
            background-color: #f0f3f5;
            border-bottom: 1px solid #e4e5e6;

            &:first-child{
                border-radius: calc(0.55rem - 1px) calc(0.55rem - 1px) 0 0;
            }
        }

        &-hideBox {
            font-size: 1.4em;
            border-radius: 1em;
            font-weight: bold;
            padding: 0;
            margin: 0.5em 0 1em 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            &-content {
                border-radius: 1.2em;
                background: #f0f3f5;
                padding: 1em;
                margin: 0;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
            }

            &-hidden{
                font-size: 0.9em;
                padding: 1em 1.5em;
                width: 100%;
                background: #fafbfe;
                border-bottom-left-radius: 1em;
                border-bottom-right-radius: 1em;
            }
        }
    }



}

.card-body {
    padding: 1rem!important;
}

.font {

    &-medium {
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 1200px){
    .form-title {
        font-size: 1.3em;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1199px) {
    .form-title {
        font-size: 1.1em;
    }
}

@media only screen and (min-width: 768px) and (max-width: 899px) {
    .form-title {
        font-size: 1em;
    }
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
    .form-title {
        font-size: 0.9em;
    }
}

@media only screen and (max-width: 499px) {
    .form-title {
        font-size: 0.8em;
    }
}

.btn-full {
    width: 100%;
}
